import ArticleCardList from '@dustin-web/content-components/src/article-card-list'
import { useSiteContext, useTranslations } from '@dustin-web/microsite-components/src/hooks'
import { withArticles } from '@dustin-web/microsite-data/src/hocs'
import Button from '@dustin-web/microsite-components/src/button'
import { getGuidesUrl, getKnowledgeBankUrl } from '@dustin-web/microsite-config'

export type KnowledgeBankProps = { tags: string[] }

const ArticlesListWithData = withArticles(props => {
  const { translate } = useTranslations()
  if (!props.articlesList.length) {
    return translate('No_Articles') // needs to refactor and not display entire section if no articles are found
  }
  if (props.isLoading) {
    return '' // needs to add display articles sceleton
  }
  return <ArticleCardList {...props} />
})

export const KnowledgeBank = ({ tags }: KnowledgeBankProps) => {
  const { translate } = useTranslations()
  const { sourcingCompany, isBusiness } = useSiteContext()
  return (
    <div className="u-bg-light-brown u-py-48 ">
      <div className="container">
        <h2 className="u-mb-16 u-color-dark-brown">
          {isBusiness ? translate('Header_KnowledgeBank') : translate('Let_Us_Inspire_You')}
        </h2>
        <ArticlesListWithData numberOfBoxes={4} tags={tags} excludeDate showFourCardsPerRow />
        <div className="d-flex justify-content-end">
          <Button
            primary
            icon="arrow-right"
            link
            aria-label={isBusiness ? translate('To_The_Knowledge_Bank') : translate('ShowMore')}
            href={isBusiness ? getKnowledgeBankUrl(sourcingCompany) : getGuidesUrl(sourcingCompany)}
            text={isBusiness ? translate('To_The_Knowledge_Bank') : translate('ShowMore')}
            className="u-bg-dark-brown u-color-light-brown u-mb-0"
          />
        </div>
      </div>
    </div>
  )
}
